import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import urlJoin from 'proper-url-join';

import { SHOP_PAGE_PATHNAME } from '../../../lib/CONST';
import {
  categoryLabel,
  mujerCategoriasObjects,
  infantesCategoriasObjects,
} from '../../../lib/filterLabels';
import Link from '../../Link';
import { ITEM_ONLINE_UNIVERSO } from '../../../lib/GQL_ENUMS';
import { createUrl } from '../../../features/search/store/main/utils';

const icons = '/images/iconos/spritesheet.png';

const iconStyles = (categoriasObjects, universo) => categoriasObjects.reduce((accum, c) => {
  const a = { ...accum };
  a[universo + c.name] = {
    width: 40,
    height: 40,
    backgroundImage: `url(${c.icon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition:
        universo === ITEM_ONLINE_UNIVERSO.MUJER ? c.position : c.positionLabel,
    display: 'block',
  };
  return a;
}, {});

const verTodo = [
  {
    name: '',
    label: 'Ver todo',
    category: 'ver todo',
    icon: icons,
    position: '-155px -255px',
    positionLabel: '-155px -255px',
  },
];

const useStyles = makeStyles(() => ({
  ...iconStyles(
    mujerCategoriasObjects.concat(verTodo),
    ITEM_ONLINE_UNIVERSO.MUJER,
  ),
  ...iconStyles(
    infantesCategoriasObjects.concat(verTodo),
    ITEM_ONLINE_UNIVERSO.INFANTE,
  ),
}));

const generateQuery = (c) => {
  const { label } = c;
  if (label === 'Ver todo') {
    return '';
  }
  if (c.searchState) {
    return createUrl(c.searchState);
  }
  if (c.path) {
    return c.path;
  }
  return '';
};

const List = ({
  categories,
  shopPathname,
  activePage,
  closeSidebar,
  column,
}) => {
  const classes = useStyles();

  const menuMujer = shopPathname === SHOP_PAGE_PATHNAME;
  const obj = menuMujer ? mujerCategoriasObjects : infantesCategoriasObjects;
  const list = useMemo(() => {
    const createList = (categoryArr, pathname) => categoryArr
      .map((c) => categoryLabel(c, obj))
      .reduce((accum, current) => accum.concat(current), [])
      .concat(verTodo)
      .map((c, index) => (
        <div
          key={c.label}
          onClick={() => closeSidebar()}
          onKeyDown={() => closeSidebar()}
          className={`
            pt-2 pb-2 hover:bg-gray-200
            ${column === 12 ? 'w-full' : ''}
            ${column === 6 ? 'w-6/12' : ''}
            ${column === 4 ? 'w-4/12' : ''}
          `}
          role="link"
          tabIndex={index}
        >
          <Link
            href={urlJoin(pathname, generateQuery(c), {
              trailingSlash: true,
            })}
            passHref
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a>
              <div className="w-full flex flex-wrap box-border items-start">
                <div className="m-0 box-border w-2/12">
                  <div className="w-10 mb-0 pr-2">
                    <span
                      className={
                          classes[
                            (shopPathname !== SHOP_PAGE_PATHNAME
                              ? ITEM_ONLINE_UNIVERSO.INFANTE
                              : ITEM_ONLINE_UNIVERSO.MUJER) + c.name
                          ]
                        }
                    />
                  </div>
                </div>
                <div className="m-0 box-border w-10/12">
                  <p
                    className={clsx({
                      'test-primary': !!activePage[c.category],
                      'pt-2 leading-4 text-sm mb-2': true,
                    })}
                    data-testid={`menu-dropdown-category-link-${index + 1}`}
                  >
                    {c.label === 'Accesorios' && menuMujer
                      ? 'Otros'
                      : c.label}
                  </p>
                </div>
              </div>
              {c.description && (
                <p
                  className="pl-12 ml-1 text-xs text-gray-700 leading-3 mb-1"
                >
                  {c.description}
                </p>
              )}
            </a>
          </Link>
        </div>
      ));
    return createList(categories, shopPathname);
  }, [
    categories,
    shopPathname,
    obj,
    column,
    classes,
    activePage,
    menuMujer,
    closeSidebar,
  ]);

  return list;
};

List.propTypes = {
  categories: PropTypes.array.isRequired,
  shopPathname: PropTypes.string.isRequired,
  activePage: PropTypes.object,
  closeSidebar: PropTypes.func,
  column: PropTypes.number,
};

List.defaultProps = {
  activePage: {},
  closeSidebar: () => {},
  column: 12,
};

export default List;
