/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/CloseRounded';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import clsx from 'clsx';

import { useNavigate } from '../../lib/utils/navigation';
import useCurrentUser from '../../features/auth/hooks/useCurrentUser';
import MenuDropDownMobile from './MenuDropDownMobile';
import ButtonRegistrate from './ButtonRegistrate';
import ButtonIngresa from './ButtonIngresa';
import ButtonSalir from './ButtonSalir';

const classesUserButton = 'pt-4 pl-4 flex items-center justify-center';

const icon = [
  <AutorenewIcon key="autorenew" />,
  <FavoriteBorderIcon key="favorite" />,
  <MailOutlineIcon key="mailOutline" />,
  <PersonOutlinedIcon key="personOutline" />,
];
const Sidebar = ({
  isOpen, navItems, close, activePage,
}) => {
  const navigate = useNavigate();
  const goTo = (url) => {
    navigate(url);
    close();
  };
  const currUser = useCurrentUser();

  return (
    <>
      <div
        className={clsx(`
          fixed bg-white top-0 bottom-0 right-0 overflow-y-scroll px-5 py-0 z-[1000]
          w-[300px] left-[calc(100vw-300px)]
          sm:w-[400px] sm:left-[calc(100vw-400px)]
        `, { hidden: !isOpen })}
        data-testid="sidebar-mobile-menu"
      >
        <button type="button" onClick={close} className="w-1/5 absolute top-4 right-0">
          <CloseIcon />
        </button>

        <div className="w-full mt-8">
          {navItems.map(({ url, name, title }, index) => (
            <Fragment key={`Sidebar-${name}`}>
              <button
                type="button"
                onClick={() => goTo(url)}
                onKeyDown={() => goTo(url)}
                title={title || name}
                className={clsx({
                  'hover:text-red-600 cursor-pointer w-1/4': true,
                  'text-red-600': !!activePage[url.replace('/', '')],
                })}
              >
                {icon[index]}
                <p>{name}</p>
              </button>
            </Fragment>
          ))}
        </div>
        <MenuDropDownMobile closeSidebar={close} activePage={activePage} />
        {currUser ? (
          <div className={classesUserButton}>
            <ButtonSalir closeSidebar={close} classNames="w-2/5" />
          </div>
        ) : (
          <div className={clsx([classesUserButton, 'justify-around'])}>
            <ButtonRegistrate closeSidebar={close} />
            {' '}
            o
            {' '}
            <ButtonIngresa closeSidebar={close} />
          </div>
        )}
      </div>
      {isOpen && (
        <div
          key="SidebarOverlay"
          className="fixed left-0 top-0 right-0 bottom-0 z-10 opacity-80 bg-black"
          onClick={close}
          onKeyPress={close}
        />
      )}
    </>
  );
};

Sidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  navItems: PropTypes.arrayOf(PropTypes.shape).isRequired,
  close: PropTypes.func.isRequired,
  activePage: PropTypes.object.isRequired,
};

export default Sidebar;
