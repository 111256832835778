import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
// import ButtonLT from '../utils/elements/ButtonLT';
import clsx from 'clsx';

import {
  SHOP_PAGE_PATHNAME,
  SHOP_INFANTE_PAGE_PATHNAME,
} from '../../../lib/CONST';
import {
  ROPA,
  ACCESORIOS,
  ITEM_ONLINE_INFANTE_FILTER_CATEGORIA,
} from '../../../lib/filterLabels';
import { colorPrimary } from '../../utils/elements/muiStyles';
import styles from '../subHeader.module.css';

import MenuDropDownList from '../MenuDropDown/List';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none',
    margin: '0 !important',
    width: '100%',
  },
  Accordion: {
    width: '100%',
    padding: 0,
    '& div': {
      marginTop: 8,
      marginBottom: 8,
      height: 10,
      marginRight: 0,
    },
    '& p': {
      paddingLeft: 20,
    },
    '& p:hover': {
      color: colorPrimary,
    },
  },
  AccordionDetail: {
    padding: '8px 0px 0px 4px',
  },
  pannel: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
    '& p': {
      paddingLeft: 20,
    },
    '& p:hover': {
      color: colorPrimary,
    },
  },
  list: {
    width: '100%',
    listStyle: 'none',
    marginLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    '& li': {
      width: '100%',
    },
  },
  icon: {
    width: 40,
    verticalAlign: 'middle',
    marginBottom: 0,
    marginLeft: 10,
    paddingRight: 10,
  },
  iconContainer: {
    minWidth: 50,
  },
  prontoLabel: {
    // left: -15,
    top: -4,
    width: 50,
  },
  caret: {
    paddingRight: 0,
  },
  activeButton: {
    color: '#ff3652',
  },
  section: {
    paddingLeft: 20,
  },
}));

const classes2 = {
  padding: 'py-3 px-0',
};

const badgeClasses = `
  absolute inline-flex items-center justify-center
  px-2 py-1
  text-xs font-bold leading-none text-white
  bg-primaryDarken rounded-full
  translate-x-7 -translate-y-3.5
`;

const ShopMenu = ({ closeSidebar, activePage }) => {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
        className={classes.root}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className={classes.Accordion}
        >
          <Typography
            element="p"
            variant="body1"
            className={clsx({
              [classes.activeButton]:
                !!activePage.ropa || !!activePage.accesorios,
            })}
          >
            Mujer
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.AccordionDetail}>
          <List component="ul" aria-label="menu" className={classes.list}>
            <Typography
              element="p"
              variant="body1"
              color="textSecondary"
              className={clsx({
                [classes.activeButton]: !!activePage.ropa,
                [classes.section]: true,
              })}
            >
              ROPA
            </Typography>
            <MenuDropDownList
              categories={ROPA}
              shopPathname={SHOP_PAGE_PATHNAME}
              activePage={activePage}
              closeSidebar={closeSidebar}
            />
            <Typography
              element="p"
              variant="body1"
              color="textSecondary"
              className={clsx({
                [classes.activeButton]: !!activePage.accesorios,
                [classes.section]: true,
              })}
            >
              ACCESORIOS
            </Typography>
            <MenuDropDownList
              categories={ACCESORIOS}
              shopPathname={SHOP_PAGE_PATHNAME}
              activePage={activePage}
              closeSidebar={closeSidebar}
            />
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}
        className={classes.root}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
          className={classes.Accordion}
        >
          <Typography
            element="p"
            variant="body1"
            className={clsx({
              [classes.activeButton]: !!activePage.infantes,
            })}
            data-testid="sidebar-menu-ninxs-button"
          >
            Niñ
            <span className={clsx([styles.heartEmoji, styles.red])} />
            s
            {/* Niñ💚s */}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.AccordionDetail}>
          <List component="ul" aria-label="menu" className={classes.list}>
            <MenuDropDownList
              categories={Object.keys(ITEM_ONLINE_INFANTE_FILTER_CATEGORIA)}
              shopPathname={SHOP_INFANTE_PAGE_PATHNAME}
              activePage={activePage}
              closeSidebar={closeSidebar}
            />
          </List>
        </AccordionDetails>
      </Accordion>
      <Box
        onClick={() => closeSidebar()}
        className={clsx({
          [classes.pannel]: true,
          'border-t border-t-solid border-t-[rgba(0, 0, 0, 0.2)]': true,
        })}
      >
        <Link href="/shop/lo-ultimo/" passHref>
          <Typography
            element="p"
            variant="body1"
            className={clsx({
              [classes2.padding]: true,
              [classes.activeButton]: !!activePage['shop/lo-ultimo'],
            })}
          >
            Lo último
          </Typography>
        </Link>
      </Box>
      <Box onClick={() => closeSidebar()} className={classes.pannel}>
        <Link href="/shop/popular/" passHref>
          <Typography
            element="p"
            variant="body1"
            className={clsx({
              [classes2.padding]: true,
              [classes.activeButton]: !!activePage.popular,
            })}
          >
            Populares
          </Typography>
        </Link>
      </Box>
      <Box onClick={() => closeSidebar()} className={classes.pannel}>
        <Link href="/shop/sale/" passHref>
          <Typography
            element="p"
            variant="body1"
            className={clsx({
              [classes2.padding]: true,
              [classes.activeButton]: !!activePage.sale,
            })}
          >
            <>
              <span className={badgeClasses}>SALE</span>
              REBAJAS
            </>
          </Typography>
        </Link>
      </Box>
      <Box
        onClick={() => closeSidebar()}
        className={classes.pannel}
      >
        <Link href="/shop-ninxs/sale/" passHref>
          <Typography
            element="p"
            variant="body1"
            className={clsx({
              [classes2.padding]: true,
              [classes.activeButton]: !!activePage.sale,
            })}
          >
            <>
              <span className={badgeClasses}>SALE</span>
              NIÑ
              <span className={clsx([styles.heartEmoji, styles.red])} />
              S
            </>
          </Typography>
        </Link>
      </Box>
    </>
  );
};

ShopMenu.propTypes = {
  closeSidebar: PropTypes.func.isRequired,
  activePage: PropTypes.object.isRequired,
};

export default ShopMenu;
